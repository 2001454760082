.ow_activity_hmb {
    margin-bottom: 0.5rem;
}

.ow_activity_daylabel {
    text-transform: "capitalize";
}

.ow_activity_export_class {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ow_activity_member_footer {
    align-items: center;
    justify-content: center;
}

.ow_activity_staff_member {
    height: 100px;
    width: 100px;
    cursor: pointer;
}

.ow_activity_replicate_button {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.ow_activity_aes {
    color: #dc3545;
    cursor: pointer;
    margin-left: 1rem;
}

.ow_activity_ses {
    color: #28a745;
    margin-left: 1rem;
}

.ow_activity_warn_smpt_message {
    color: #ffc107;
}