@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-phone-input-2/lib/style.css';
@import url("https://cdn.syncfusion.com/ej2/bootstrap4.css");

@import './colors.css';
@import "./activityDetail.css";

.ow_activity_caw {
    cursor: pointer;
}

.ow_activity_ali {
    max-height: 60px;
    max-width: 100px;
}

.ow_activity_mtr {
    margin-top: 1rem;
}

.ow_activity_active_switch {
    margin-top: 0.5rem;
}

.ow_activity_info_test_mail {
    margin-left: .8rem;
    margin-bottom: 0.5rem;
    font-size: .8rem;
}

.ow_activity_download_icon {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.ow_activity_send_icon {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.4rem;
    color: var(--primary--color)
}

.ow_activity_image_info {
    font-size: 0.7rem;
    color: var(--busy--color);
}

.ow_activity_halfbold {
    font-weight: 500;
}

.ow_activity_coi {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--busy--color);
    float: right;
}

.ow_activity_members_table {
    margin-bottom: 0rem;
}

.ow_activity_member_delete {
    text-align: center;
}

.ow_activity_reward_image {
    min-height: 3rem;
    min-width: 3rem;
    max-height: 5rem;
    max-width: 5rem;
}

.ow_activity_qrcodelink {
    font-size: 0.8rem;
}

.ow_activity_room_image {
    min-height: 3rem;
    min-width: 3rem;
    max-height: 5rem;
    max-width: 5rem;
}

.ow_activity_imagetablogo {
    cursor: pointer;
    min-height: 3rem;
    min-width: 3rem;
    max-height: 5rem;
    max-width: 5rem;
}

.ow_activity_tac{
    max-height: 30rem;
    overflow: auto;
}

.create_poi_button {
    width: 100%;
    height: 4rem;
}

.connect_nearby_poi_message {
    text-align: center;
    font-size: 0.8rem;
    color: #6c757d;
}